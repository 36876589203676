import React, { useState } from "react";
import SidePage from "../../components/get-a-quotation-form";
import EmailForm from "./form/product-yarn-email-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

export default function EcosAmy() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <div style={{ marginTop: "50px" }}>
        <div className="ecose-page-gap">
          <div className="two-column-para">
            <div className="column-para column1-para">
              {/* Column 1 */}
              <img
                className="ecose-page-image"
                alt=""
                src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/product-yarn/UltraWhiteNew.jpg"
              />
            </div>
            <div className="column-para column2-para">
              {/* Column 2 */}
              <img
                className="ecose-page-image-2"
                alt=""
                src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/product-yarn/ultra-white.png"
              />
              <h2 className="ecos-page-topic">
                ELEVATING WHITENESS STANDARDS WITH RECYCLED PLASTIC YARN
              </h2>
              <p className="ecos-page-para">
                Our Recycled Plastic Yarn is an eco-friendly innovation that
                addresses whiteness standards. It offers a versatile,
                consistent, and customizable alternative to traditional dyeing,
                ideal for environmentally-conscious creators seeking precision
                and sustainability in their work. Embrace the future of
                eco-responsible textiles.
              </p>
              <h2 className="ecos-page-topic">Potential Benefits:</h2>
              <p className="ecos-page-para">
                {/* <p className="ecos-page-sub-para">
                  Ensures better odour control
                </p>
                <p className="ecos-page-sub-para">
                  Inhibits the growth of bacteria and fungus which protects
                  wearer from harmful microbes
                </p>
                <p className="ecos-page-sub-para">
                  Active antimicrobial properties proven to withstand more than
                  50 wash cycles
                </p> */}
                <table style={{ width: "100%", textAlign: "start" }}>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        Sustainability: Reduce plastic waste.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        Cost-Efficiency: Eliminate dyeing costs.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        Whiteness Precision: Achieve consistent standards.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        Customization: Tailor whiteness as needed.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        Eco-friendly: Create sustainably.
                      </p>
                    </td>
                  </tr>
                </table>
              </p>
              <div className="horizontal-container">
                <div className="topic-container">
                  <div>
                    <p className="horizontal-line-ecos-text">END USES:</p>
                  </div>
                  <div className="horizontal-line-ecos right"></div>
                </div>
                <img
                  className="ecose-page-image-p"
                  alt=""
                  src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/product-yarn/loop-end.jpg"
                />
              </div>
              <br />
              <div>
                <div className="product-yarn-button-container">
                  <a
                    onClick={toggleSidebar}
                    className="product-yarn-button-column"
                  >
                    <p className="product-yarn-button-text">
                      <center>GET A QUOTATION</center>
                    </p>
                  </a>

                  <a
                    href="/ecos-ultra-white/UltraWhite.pdf"
                    // href="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/UltraWhite.pdf"
                    className="product-yarn-button-column"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="product-yarn-button-text">
                      <center>DOWNLOAD BROUCHURE</center>
                    </p>
                  </a>
                </div>
              </div>

              <div style={{ marginLeft: "10px" }}>
                <EmailForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSidebarOpen && <SidePage closeSidebar={toggleSidebar} />}
    </div>
  );
}
