import React, { useState } from "react";
import EcosAmy from "./ecos-amy";
import EcosGreen from "./ecos-green";
import EcosEvolve from "./ecos-evolve";
import EcosShield from "./ecos-shield";
import EcosAquacool from "./ecos-aquacool";
import EcosOceanic from "./ecos-oceanic";
import EcosChroma from "./ecos-chroma";
import EcosLoop from "./ecos-loop";
import UltraWhite from "./ecos-ultra-white";
import Softflex from "./ecos-softflex";
import "./yarn-navbar.css";
import MobileBar from "./yarn-navbar-mobile";

const NavBar = () => {
  const [selectedTab, setSelectedTab] = useState("ecosamy");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleKeyDown = (e, tab) => {
    if (e.key === "Enter" || e.key === " ") {
      setSelectedTab(tab);
    }
  };

  const renderPage = () => {
    switch (selectedTab) {
      case "ecosamy":
        return <EcosAmy />;
      case "ecosgreen":
        return <EcosGreen />;
      case "ecosevolve":
        return <EcosEvolve />;
      case "ecosshield":
        return <EcosShield />;
      case "ecosaquacool":
        return <EcosAquacool />;
      case "ecosoceanic":
        return <EcosOceanic />;
      case "ecoschroma":
        return <EcosChroma />;
      case "ecosloop":
        return <EcosLoop />;
      case "ultrawhite":
        return <UltraWhite />;
      case "softflex":
        return <Softflex />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="pc">
        <nav className="yarn-navbar">
          <ul>
            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "ecosamy" ? "selected" : ""}
              onClick={() => handleTabClick("ecosamy")}
              onKeyDown={(e) => handleKeyDown(e, "ecosamy")}
            >
              <h2 className="tab-lable-2">Ecos Amy</h2>
            </li>

            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "ecosgreen" ? "selected" : ""}
              onClick={() => handleTabClick("ecosgreen")}
              onKeyDown={(e) => handleKeyDown(e, "ecosgreen")}
            >
              <h2 className="tab-lable-2">Ecos Green</h2>
            </li>

            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "ecosevolve" ? "selected" : ""}
              onClick={() => handleTabClick("ecosevolve")}
              onKeyDown={(e) => handleKeyDown(e, "ecosevolve")}
            >
              <h2 className="tab-lable-2">Ecos Evolve</h2>
            </li>

            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "ecosshield" ? "selected" : ""}
              onClick={() => handleTabClick("ecosshield")}
              onKeyDown={(e) => handleKeyDown(e, "ecosshield")}
            >
              <h2 className="tab-lable-2">Ecos Shield</h2>
            </li>

            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "ecosoceanic" ? "selected" : ""}
              onClick={() => handleTabClick("ecosoceanic")}
              onKeyDown={(e) => handleKeyDown(e, "ecosoceanic")}
            >
              <h2 className="tab-lable-2">Ecos Oceanic</h2>
            </li>

            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "ecoschroma" ? "selected" : ""}
              onClick={() => handleTabClick("ecoschroma")}
              onKeyDown={(e) => handleKeyDown(e, "ecoschroma")}
            >
              <h2 className="tab-lable-2">Ecos Chroma</h2>
            </li>

            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "ecosaquacool" ? "selected" : ""}
              onClick={() => handleTabClick("ecosaquacool")}
              onKeyDown={(e) => handleKeyDown(e, "ecosaquacool")}
            >
              <h2 className="tab-lable-2">Ecos Aquacool</h2>
            </li>

            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "ecosloop" ? "selected" : ""}
              onClick={() => handleTabClick("ecosloop")}
              onKeyDown={(e) => handleKeyDown(e, "ecosloop")}
            >
              <h2 className="tab-lable-2">Ecos Loop</h2>
            </li>

            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "ultrawhite" ? "selected" : ""}
              onClick={() => handleTabClick("ultrawhite")}
              onKeyDown={(e) => handleKeyDown(e, "ultrawhite")}
            >
              <h2 className="tab-lable-2">Ultra White</h2>
            </li>
            
            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "softflex" ? "selected" : ""}
              onClick={() => handleTabClick("softflex")}
              onKeyDown={(e) => handleKeyDown(e, "softflex")}
            >
              <h2 className="tab-lable-2">Ecos Softflex</h2>
            </li>
          </ul>
        </nav>
        {renderPage()}
      </div>
      <div className="mobile">
        <MobileBar />
      </div>
    </div>
  );
};

export default NavBar;
